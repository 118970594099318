<script setup lang="ts">
import { CfgButton } from '@cfgtech/ui'
import type { SbButtonStoryblok } from '~/types/storyblok'

const props = defineProps<{ blok: SbButtonStoryblok }>()

const to = computed(() => {
  if (!props.blok.link) {
    return undefined
  }

  let link = props.blok.link.cached_url || '/'

  if (props.blok.link.anchor) {
    link += `#${props.blok.link.anchor}`
  }

  return props.blok.link.linktype === 'story' ? `/${link}` : link
})

const buttonVariant = computed(() => {
  if (props.blok.variant === 'text') {
    return { text: true }
  }

  if (props.blok.variant === 'secondary') {
    return {
      stroke: true,
      variant: 'primary',
    }
  }

  return {
    variant: 'primary',
  }
})
</script>

<template>
  <div
    v-editable="blok"
    class="flex w-full justify-center lg:w-[unset] lg:justify-normal"
    :class="{
      'first-of-type:2 mt-2 first-of-type:mt-0 last-of-type:mb-0 lg:ml-3 lg:mt-0 lg:first-of-type:ml-0 lg:first-of-type:mr-3 lg:last-of-type:mr-0': blok.variant === 'text',
    }"
  >
    <NuxtLink
      class="flex w-full justify-center lg:justify-normal"
      :target="blok.link?.target"
      :to="to"
    >
      <CfgButton
        v-bind="buttonVariant"
        as="span"
        class="min-w-full"
        :icon-placement="blok.iconPlacement || undefined"
        :label="blok.label"
        :small="blok.small"
      >
        <template v-if="blok.icon?.filename" #icon>
          <LazyNuxtPicture
            :alt="blok.icon?.alt"
            class="size-[24px]"
            height="24"
            :img-attrs="{
              class: 'size-full object-cover !size-[24px]',
            }"
            provider="storyblok"
            :src="blok.icon?.filename"
            width="24"
          />
        </template>
      </CfgButton>
    </NuxtLink>
  </div>
</template>
